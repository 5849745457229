<template>
  <div class="text-center">
    <p class="text-dark font-size-48 font-weight-bold mb-2">
      $29,931
    </p>
    <p class="text-uppercase text-muted mb-3">
      Revenue today
    </p>
    <p class="mb-4">
      Lorem ipsum dolor sit amit,consectetur eiusmdd tempory incididunt ut labore et dolore magna elit
    </p>
    <a href="javascript: void(0);" class="btn btn-outline-primary mb-1">View history</a>
  </div>
</template>
<script>
export default {
  name: 'KitGeneral1v1',
}
</script>
