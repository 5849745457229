<template>
  <div class="row">
    <div class="col-xl-4 col-lg-12">
      <div>
        <h2 class="badge-example">General / 14</h2>
        <div class="card">
          <div class="py-3 px-4">
            <div class="d-flex flex-wrap mb-2">
              <div class="text-dark font-size-18 font-weight-bold mr-auto">Support Chat</div>
              <button type="button" class="btn btn-link p-0 border-0">
                <i class="fe fe-x-square font-size-21 align-middle text-gray-6" />
              </button>
            </div>
            <kit-general-14 />
          </div>
        </div>
      </div>
      <div>
        <h2 class="badge-example">General / 12</h2>
        <div class="card">
          <div class="card-body">
            <kit-general-12 />
          </div>
        </div>
      </div>
      <div>
        <h2 class="badge-example">General / 12-1</h2>
        <div class="card text-white bg-primary">
          <kit-general-12v1 />
        </div>
      </div>
      <div>
        <h2 class="badge-example">General / 12-2</h2>
        <div class="card">
          <div class="card-body">
            <kit-general-12v2 />
          </div>
        </div>
      </div>
      <div>
        <h2 class="badge-example">General / 12-3</h2>
        <div class="card">
          <div class="card-body bg-light">
            <kit-general-12v3 />
          </div>
        </div>
      </div>
      <div>
        <h2 class="badge-example">General / 13</h2>
        <div class="card">
          <kit-general-13 />
        </div>
      </div>
      <div>
        <h2 class="badge-example">General / 13-1</h2>
        <div class="card">
          <kit-general-13v1 />
        </div>
      </div>
    </div>
    <div class="col-xl-4 col-lg-12">
      <div>
        <h2 class="badge-example">General / 1</h2>
        <div class="card">
          <div class="card-body">
            <kit-general-1 />
          </div>
        </div>
      </div>
      <div>
        <h2 class="badge-example">General / 1-1</h2>
        <div class="card">
          <div class="card-body">
            <kit-general-1v1 />
          </div>
        </div>
      </div>
      <div>
        <h2 class="badge-example">General / 9</h2>
        <div class="card">
          <div class="card-body">
            <kit-general-9 />
          </div>
        </div>
      </div>
      <div>
        <h2 class="badge-example">General / 10</h2>
        <div class="card">
          <div class="card-body">
            <kit-general-10 />
          </div>
        </div>
      </div>
      <div>
        <h2 class="badge-example">General / 10-1</h2>
        <div class="card">
          <div class="card-body">
            <kit-general-10v1 />
          </div>
        </div>
      </div>
      <div>
        <h2 class="badge-example">General / 5-1</h2>
        <div class="card">
          <div class="card-body">
            <kit-general-5v1 />
          </div>
        </div>
      </div>
      <div>
        <h2 class="badge-example">General / 11</h2>
        <div class="card">
          <kit-general-11 />
        </div>
      </div>
      <div>
        <h2 class="badge-example">General / 11-1</h2>
        <div class="card">
          <kit-general-11v1 />
        </div>
      </div>
    </div>
    <div class="col-xl-4 col-lg-12">
      <div>
        <h2 class="badge-example">General / 2</h2>
        <div class="card">
          <div class="card-body">
            <kit-general-2 />
          </div>
        </div>
      </div>
      <div>
        <h2 class="badge-example">General / 2-1</h2>
        <div class="card">
          <div class="card-body">
            <kit-general-2v1 />
          </div>
        </div>
      </div>
      <div>
        <h2 class="badge-example">General / 2-2</h2>
        <div class="card">
          <div class="card-body">
            <kit-general-2v2 />
          </div>
        </div>
      </div>
      <div>
        <h2 class="badge-example">General / 2-3</h2>
        <div class="card text-white bg-success">
          <div class="card-body">
            <kit-general-2v3 />
          </div>
        </div>
      </div>
      <div>
        <h2 class="badge-example">General / 2-4</h2>
        <div class="card text-white bg-danger">
          <div class="card-body">
            <kit-general-2v4 />
          </div>
        </div>
      </div>
      <div>
        <h2 class="badge-example">General / 3</h2>
        <div class="card">
          <div class="card-body">
            <kit-general-3 />
          </div>
        </div>
      </div>
      <div>
        <h2 class="badge-example">General / 3-1</h2>
        <div class="card bg-primary">
          <div class="card-body">
            <kit-general-3v1 />
          </div>
        </div>
      </div>
      <div>
        <h2 class="badge-example">General / 4</h2>
        <div class="card">
          <div class="card-body">
            <kit-general-4 />
          </div>
        </div>
      </div>
      <div>
        <h2 class="badge-example">General / 5</h2>
        <div class="card">
          <div class="card-body">
            <kit-general-5 />
          </div>
        </div>
      </div>
      <div>
        <h2 class="badge-example">General / 6</h2>
        <div class="card">
          <kit-general-6 />
        </div>
      </div>
      <div>
        <h2 class="badge-example">General / 6-1</h2>
        <div class="card">
          <kit-general-6v1 />
        </div>
      </div>
      <div>
        <h2 class="badge-example">General / 7</h2>
        <div class="card">
          <div class="card-body">
            <kit-general-7 />
          </div>
        </div>
      </div>
      <div>
        <h2 class="badge-example">General / 8</h2>
        <div class="card">
          <div class="card-body">
            <kit-general-8 />
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-4 col-lg-12">
      <div>
        <h2 class="badge-example">General / 16</h2>
        <kit-general-16
          isNew
          isFavourite
          image="resources/images/products/003.jpg"
          name="TP-Link AC1750 Smart WiFi Router - Dual Band Gigabit Wireless Internet Routers for Home"
          price="245.21"
          oldPrice="419.58"
        />
      </div>
    </div>
    <div class="col-xl-8 col-lg-12">
      <div>
        <h2 class="badge-example">General / 15</h2>
        <div class="card">
          <div class="card-body">
            <kit-general-15 />
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-4">
      <div>
        <h2 class="badge-example">General / 17</h2>
        <div class="card">
          <kit-general-17 />
        </div>
      </div>
    </div>
    <div class="col-lg-4">
      <div>
        <h2 class="badge-example">General / 17-1</h2>
        <div class="card">
          <kit-general-17v1 />
        </div>
      </div>
    </div>
    <div class="col-lg-4">
      <div>
        <h2 class="badge-example">General / 17-2</h2>
        <div class="card">
          <kit-general-17v2 />
        </div>
      </div>
    </div>
    <div class="col-lg-6">
      <div>
        <h2 class="badge-example">General / 18</h2>
        <div class="card">
          <kit-general-18 />
        </div>
      </div>
    </div>
    <div class="col-lg-6">
      <div>
        <h2 class="badge-example">General / 18-1</h2>
        <div class="card">
          <kit-general-18v1 />
        </div>
      </div>
    </div>
    <div class="col-lg-6">
      <div>
        <h2 class="badge-example">General / 19</h2>
        <div class="card">
          <kit-general-19 />
        </div>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="row">
        <div class="col-lg-6">
          <div>
            <h2 class="badge-example">General / 20</h2>
            <kit-general-20 />
          </div>
        </div>
        <div class="col-lg-6">
          <div>
            <h2 class="badge-example">General / 20-1</h2>
            <kit-general-20v1 />
          </div>
        </div>
        <div class="col-lg-6">
          <div>
            <h2 class="badge-example">General / 21</h2>
            <kit-general-21 />
          </div>
        </div>
        <div class="col-lg-6">
          <div>
            <h2 class="badge-example">General / 21-1</h2>
            <kit-general-21v1 />
          </div>
        </div>
        <div class="col-lg-6">
          <div>
            <h2 class="badge-example">General / 21-2</h2>
            <kit-general-21v2 />
          </div>
        </div>
        <div class="col-lg-6">
          <div>
            <h2 class="badge-example">General / 21-3</h2>
            <kit-general-21v3 />
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-12">
      <div>
        <h2 class="badge-example">General / 22</h2>
        <div class="card">
          <div class="card-body">
            <kit-general-22 />
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-3">
      <div>
        <h2 class="badge-example">General / 23</h2>
        <div class="card bg-light">
          <div class="card-body">
            <kit-general-23 />
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-3">
      <div>
        <h2 class="badge-example">General / 23-1</h2>
        <div class="card">
          <div class="card-body">
            <kit-general-23v1 />
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="row">
        <div class="col-lg-12">
          <div>
            <h2 class="badge-example">General / 24</h2>
            <div class="card bg-primary border-0">
              <div class="card-body">
                <kit-general-24 />
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-12">
          <div>
            <h2 class="badge-example">General / 24-1</h2>
            <div class="card bg-light">
              <div class="card-body">
                <kit-general-24v1 />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6">
      <div>
        <h2 class="badge-example">General / 27</h2>
        <div class="card">
          <div class="card-body">
            <kit-general-27 />
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6">
      <div>
        <h2 class="badge-example">General / 27-1</h2>
        <div class="card">
          <div class="card-body">
            <kit-general-27v1 />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import KitGeneral1 from '@/components/kit/widgets/General/1'
import KitGeneral1v1 from '@/components/kit/widgets/General/1v1'
import KitGeneral2 from '@/components/kit/widgets/General/2'
import KitGeneral2v1 from '@/components/kit/widgets/General/2v1'
import KitGeneral2v2 from '@/components/kit/widgets/General/2v2'
import KitGeneral2v3 from '@/components/kit/widgets/General/2v3'
import KitGeneral2v4 from '@/components/kit/widgets/General/2v4'
import KitGeneral3 from '@/components/kit/widgets/General/3'
import KitGeneral3v1 from '@/components/kit/widgets/General/3v1'
import KitGeneral4 from '@/components/kit/widgets/General/4'
import KitGeneral5 from '@/components/kit/widgets/General/5'
import KitGeneral5v1 from '@/components/kit/widgets/General/5v1'
import KitGeneral6 from '@/components/kit/widgets/General/6'
import KitGeneral6v1 from '@/components/kit/widgets/General/6v1'
import KitGeneral7 from '@/components/kit/widgets/General/7'
import KitGeneral8 from '@/components/kit/widgets/General/8'
import KitGeneral9 from '@/components/kit/widgets/General/9'
import KitGeneral10 from '@/components/kit/widgets/General/10'
import KitGeneral10v1 from '@/components/kit/widgets/General/10v1'
import KitGeneral11 from '@/components/kit/widgets/General/11'
import KitGeneral11v1 from '@/components/kit/widgets/General/11v1'
import KitGeneral12 from '@/components/kit/widgets/General/12'
import KitGeneral12v1 from '@/components/kit/widgets/General/12v1'
import KitGeneral12v2 from '@/components/kit/widgets/General/12v2'
import KitGeneral12v3 from '@/components/kit/widgets/General/12v3'
import KitGeneral13 from '@/components/kit/widgets/General/13'
import KitGeneral13v1 from '@/components/kit/widgets/General/13v1'
import KitGeneral14 from '@/components/kit/widgets/General/14'
import KitGeneral15 from '@/components/kit/widgets/General/15'
import KitGeneral16 from '@/components/kit/widgets/General/16'
import KitGeneral17 from '@/components/kit/widgets/General/17'
import KitGeneral17v1 from '@/components/kit/widgets/General/17v1'
import KitGeneral17v2 from '@/components/kit/widgets/General/17v2'
import KitGeneral18 from '@/components/kit/widgets/General/18'
import KitGeneral18v1 from '@/components/kit/widgets/General/18v1'
import KitGeneral19 from '@/components/kit/widgets/General/19'
import KitGeneral20 from '@/components/kit/widgets/General/20'
import KitGeneral20v1 from '@/components/kit/widgets/General/20v1'
import KitGeneral21 from '@/components/kit/widgets/General/21'
import KitGeneral21v1 from '@/components/kit/widgets/General/21v1'
import KitGeneral21v2 from '@/components/kit/widgets/General/21v2'
import KitGeneral21v3 from '@/components/kit/widgets/General/21v3'
import KitGeneral22 from '@/components/kit/widgets/General/22'
import KitGeneral23 from '@/components/kit/widgets/General/23'
import KitGeneral23v1 from '@/components/kit/widgets/General/23v1'
import KitGeneral24 from '@/components/kit/widgets/General/24'
import KitGeneral24v1 from '@/components/kit/widgets/General/24v1'
import KitGeneral27 from '@/components/kit/widgets/General/27'
import KitGeneral27v1 from '@/components/kit/widgets/General/27v1'

export default {
  components: {
    KitGeneral1,
    KitGeneral1v1,
    KitGeneral2,
    KitGeneral2v1,
    KitGeneral2v2,
    KitGeneral2v3,
    KitGeneral2v4,
    KitGeneral3,
    KitGeneral3v1,
    KitGeneral4,
    KitGeneral5,
    KitGeneral5v1,
    KitGeneral6,
    KitGeneral6v1,
    KitGeneral7,
    KitGeneral8,
    KitGeneral9,
    KitGeneral10,
    KitGeneral10v1,
    KitGeneral11,
    KitGeneral11v1,
    KitGeneral12,
    KitGeneral12v1,
    KitGeneral12v2,
    KitGeneral12v3,
    KitGeneral13,
    KitGeneral13v1,
    KitGeneral14,
    KitGeneral15,
    KitGeneral16,
    KitGeneral17,
    KitGeneral17v1,
    KitGeneral17v2,
    KitGeneral18,
    KitGeneral18v1,
    KitGeneral19,
    KitGeneral20,
    KitGeneral20v1,
    KitGeneral21,
    KitGeneral21v1,
    KitGeneral21v2,
    KitGeneral21v3,
    KitGeneral22,
    KitGeneral23,
    KitGeneral23v1,
    KitGeneral24,
    KitGeneral24v1,
    KitGeneral27,
    KitGeneral27v1,
  },
}
</script>
