<template>
  <div class="d-flex flex-wrap">
    <div class="w-50 mb-3">
      <div class="kit__utils__avatarGroup">
        <div class="kit__utils__avatar kit__utils__avatar--size46">
          <img src="resources/images/avatars/1.jpg" alt="User 1" />
        </div>
        <div class="kit__utils__avatar kit__utils__avatar--size46">
          <img src="resources/images/avatars/2.jpg" alt="User 2" />
        </div>
        <div class="kit__utils__avatar kit__utils__avatar--size46">
          <img src="resources/images/avatars/3.jpg" alt="User 3" />
        </div>
        <div class="kit__utils__avatar kit__utils__avatar--size46">
          <img src="resources/images/avatars/4.jpg" alt="User 4" />
        </div>
        <button type="button" class="kit__utils__avatarGroupAdd">
          <i class="fe fe-plus" />
        </button>
      </div>
      <div class="d-flex align-items-start">
        <div class="kit__utils__avatar kit__utils__avatar--size64">
          <img src="resources/images/avatars/4.jpg" alt="User 5" />
        </div>
        <div class="kit__utils__avatar kit__utils__avatar--size46">
          <img src="resources/images/avatars/4.jpg" alt="User 6" />
        </div>
      </div>
    </div>
    <div class="w-50">
      <div class="kit__utils__avatarGroup">
        <div class="kit__utils__avatar kit__utils__avatar--size46 kit__utils__avatar--rounded">
          <img src="resources/images/avatars/1.jpg" alt="User 7" />
        </div>
        <div class="kit__utils__avatar kit__utils__avatar--size46 kit__utils__avatar--rounded">
          <img src="resources/images/avatars/2.jpg" alt="User 8" />
        </div>
        <div class="kit__utils__avatar kit__utils__avatar--size46 kit__utils__avatar--rounded">
          <img src="resources/images/avatars/3.jpg" alt="User 9" />
        </div>
        <div class="kit__utils__avatar kit__utils__avatar--size46 kit__utils__avatar--rounded">
          <img src="resources/images/avatars/4.jpg" alt="User 10" />
        </div>
        <button type="button" class="kit__utils__avatarGroupAdd">
          <i class="fe fe-plus" />
        </button>
      </div>
      <div class="d-flex align-items-start">
        <div class="kit__utils__avatar kit__utils__avatar--size64 kit__utils__avatar--rounded">
          <img src="resources/images/avatars/4.jpg" alt="User 11" />
        </div>
        <div class="kit__utils__avatar kit__utils__avatar--size46 kit__utils__avatar--rounded">
          <img src="resources/images/avatars/4.jpg" alt="User 12" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'KitGeneral9',
}
</script>
